import request from "../request";

export function fetchList() {
  return request({
    url: "/model-analysis/hydro/category/list",
  });
}
export function fetchDetail(id, pid) {
  return request({
    url: `/model-analysis/hydro/queryQVDetail/${id}/${pid}`,
  });
}

export function fetchLaterDetail(id, pid) {
  return request({
    url: `/model-analysis/hydro/queryLaterLevelDetail/${id}/${pid}`,
  });
}
