import request from '@/api/request'
import { LineLayer } from '@antv/l7'

export default {
    methods: {
        getPipe() {
            request({
                url: '/model-analysis/pipe/sewage/locations',
                method: 'post',
                data: {}
            }).then((res) => {
                if (res && Array.isArray(res.locs)) {

                    let list = []
                    for (let i = 0; i < res.locs.length; i++) {
                        const item = res.locs[i]
                        list.push({
                            "type": "Feature",
                            "properties": {
                                id: item[0],
                            },
                            "geometry": {
                                "type": "LineString",
                                "coordinates": [
                                    [parseFloat(item[1]), parseFloat(item[2])],
                                    [parseFloat(item[3]), parseFloat(item[4])],
                                ]
                            }
                        })

                    }

                    const sources = {
                        "type": "FeatureCollection",
                        "features": list
                    }

                    const pipeLayer = new LineLayer({
                        zIndex: 10,
                        depth: true
                    }).source(sources).size(2)
                        .shape('line')
                        .color('#664000')
                        .animate({
                            interval: 1, // 间隔
                            duration: 2, // 持续时间，延时
                            trailLength: 2 // 流线长度
                        });


                    this.pipeLayer = pipeLayer;
                    this.scene?.addLayer(pipeLayer);
                }
            });
        },

    }
}

