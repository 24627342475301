<template>
  <div class="selector">
    <div class="header">
      <div class="title">选择图层</div>
    </div>

    <div :id="id" style="height: 36vh; margin-top: 4vh"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { mapState } from "vuex";

echarts.use([
  TooltipComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
  LegendComponent,
  GridComponent,
]);

export default {
  props: {
    id: {
      type: String,
      default: "ability",
    },
  },

  computed: {
    ...mapState("map", ["abilityType"]),
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    this.chart = echarts.init(chartDom);
    this.setChart();
  },

  beforeDestroy() {
    this.chart?.dispose();
  },

  methods: {
    setChart() {
      if (this.abilityType.length === 0) {
        return;
      }

      var option;

      option = {
        // color: ["#4ECB73", "#FBD437", "#FFA700", "#3AA1FF"],
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: true,
          // type: "scroll",
          //   orient: "vertical",
          left: "center",
          top: "top",
        },
        grid: {
          top: "5%",
          bottom: "20%",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: this.abilityType.map((item) => {
              return {
                name: item.type,
                value: item.value,
              };
            }),
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>


<style lang="less" scoped>
.selector {
  position: fixed;
  top: 11vh;
  right: 1vw;
  z-index: 99;
  background-color: var(--bg-color);

  width: 25vw;
  padding-bottom: 1vh;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    img {
      height: 1.2vh;
    }
  }
}
</style>


  