<template>
  <div>
    <div id="map"></div>
    <Rehearsal @change="costPlannedAmountChange($event)"/>

    <WaterQualityDetail
      :visible="visible"
      :id="id"
      :pid="pid"
      @cancel="
        visible = false;
        id = '';
      "
    />
    <WaterDischargeDetail
        :visible="dischargeVisible"
        :id="id"
        :pid="pid"
        @cancel="
        dischargeVisible = false;
        id = '';
      "
    />
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

import marker1 from "@/assets/marker1.png";
import marker2 from "@/assets/marker2.png";
import marker3 from "@/assets/marker3.png";
import marker4 from "@/assets/marker4.png";
import marker5 from "@/assets/marker5.png";
import marker6 from "@/assets/marker6.png";

import markerWater from "@/assets/marker-water.png";
import markerWarning from "@/assets/marker-warning.png";

// import markerImage from "@/assets/marker.png";

import markerWaterGateActive from "@/assets/gate-active.png";

import Rehearsal from "./index.vue";
import { Scene, HeatmapLayer } from "@antv/l7";
// LayerPopup
import { GaodeMapV2 } from "@antv/l7-maps";

import sewagePipe from "./mixins/sewage-pipe";
import rainPipe from "./mixins/rain-pipe";

import WaterQualityDetail from "./components/water-quality-detail.vue";
import WaterDischargeDetail from "./components/water-discharge-detail.vue";

export default {
  mixins: [sewagePipe, rainPipe],
  components: {
    Rehearsal,
    WaterQualityDetail,
    WaterDischargeDetail,
  },

  data() {
    return {
      visible: false,
      dischargeVisible: false,
      id: "",
      pid:null,
      type:"",
    };
  },

  computed: {
    ...mapState("map", [
      "clearMap",
      "rainList",
      "waterList",
      "waterQualityList",

      "speedList",
      "rehearsalWaterList",
      "pipeList",

      "depthList",
      "inflow",
    ]),
  },

  watch: {
    clearMap(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.map) {
          this.map.clearMap();
          this.scene?.removeAllLayer();
          this.scene?.removeAllMakers();
        }
      }
    },

    rainList(newValue) {
      if (newValue) {
        console.log("rain list changed");
        this.addMarker({ type: "rain", list: newValue });
      }
    },

    waterList(newValue) {
      if (newValue) {
        console.log("water list changed");
        this.addMarker({ type: "water", list: newValue });
      }
    },

    waterQualityList(newValue) {
      if (newValue) {
        console.log("河道参数-数据更新");
        this.addLine(newValue);
      }
    },

    // 水深
    depthList(newValue) {
      // console.log("newvalue", newValue);
      this.setHeatMapLayer(newValue);
    },

    // 水位
    rehearsalWaterList(newValue) {
      if (newValue) {
        console.log("预演页面的水位列表更新");
        this.setHeatMapLayer(newValue);
      }
    },

    // 流量流速
    speedList(newValue) {
      if (newValue) {
        console.log("预演页面流速更新");
        this.addSpeedAmount({ list: newValue });
      }
    },

    // 旁侧入流量
    inflow(newValue) {
      // console.log("inflow", newValue);
      this.setInflow(newValue);
    },
  },

  mounted() {
    this.initMap();

    this.scene = new Scene({
      id: "map",
      map: new GaodeMapV2({
        mapInstance: this.map,
      }),
    });

    this.scene.addImage("water-level", require("@/assets/model/marker-bg.png"));

    this.$once("hook:beforeDestroy", () => {
      this.map?.off("click", this.onMapClick);
      this.map?.destroy();
      this.scene?.destroy();
    });
  },

  methods: {
    initMap() {
      const { AMap } = window;
      var map = new AMap.Map("map", {
        showLabel: false,
        center: [119.05098759684, 33.577462694202],
        zoom: 16,
        pitch: 55,
        viewMode: "3D",
      });

      const layer = new AMap.TileLayer.Satellite();
      map.setLayers([layer]);

      map.on("click", this.onMapClick);

      this.map = map;
    },

    onMapClick(e) {
      console.log(e);
    },

    addMarker({ type = "", list = [], remove = true }) {
      if (!this.map) {
        return;
      }
      const { AMap } = window;

      if (remove) {
        this.removeMarkerClickEvent(this.markers);
        if (Array.isArray(this.markers) && this.markers.length > 0) {
          this.map.remove(this.markers);
        }
      }

      let handledList = list;
      if (type === "rain") {
        // 降雨量
        handledList = list.map((item) => {
          let image;
          if (item.value < 10) {
            image = marker1;
          } else if (item.value < 25) {
            image = marker2;
          } else if (item.value < 50) {
            image = marker3;
          } else if (item.value < 100) {
            image = marker4;
          } else if (item.value < 250) {
            image = marker5;
          } else if (item.value >= 250) {
            image = marker6;
          }
          return {
            ...item,
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), // 图标尺寸
              image, // Icon的图像
              imageSize: new AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
            }),
          };
        });
      }

      if (type === "water") {
        handledList = list.map((item) => {
          let image = markerWater;

          return {
            ...item,
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), // 图标尺寸
              image, // Icon的图像
              // imageOffset: new AMap.Pixel(-20, -20), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
            }),
          };
        });
      }

      if (type === "warning") {
        handledList = list.map((item) => {
          let image = markerWarning;
          return {
            ...item,
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), // 图标尺寸
              image, // Icon的图像
              // imageOffset: new AMap.Pixel(-20, -20), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
            }),
          };
        });
      }

      if (type === "waterGate") {
        handledList = list.map((item) => {
          let image = markerWaterGateActive;
          return {
            ...item,
            icon: new AMap.Icon({
              size: new AMap.Size(40, 47), // 图标尺寸
              image, // Icon的图像
              // imageOffset: new AMap.Pixel(-20, -20), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(40, 47), // 根据所设置的大小拉伸或压缩图片
            }),
          };
        });
      }

      const markers = handledList.map((item) => {
        const marker = new AMap.Marker({
          icon: item.icon,
          position: new AMap.LngLat(item.x, item.y),
          // offset: new AMap.Pixel(-35, -20), // 相对于基点的偏移位置
          offset: new AMap.Pixel(-20, -20), // 相对于基点的偏移位置
          extData: {
            id: item.id,
            type,
          },
        });

        marker.on("click", this.onMarkerClick);

        return marker;
      });

      this.markers = markers;
      this.map.add(markers);
    },

    setHeatMapLayer(list) {
      // console.log(list);
      const layer = new HeatmapLayer({
        zIndex: 100,
      })
        .source(list, {
          parser: {
            type: "json",
            x: "lng",
            y: "lat",
          },
        })
        .shape("heatmap")

        .size("count", [0, 1.0])
        .style({
          rampColors: {
            colors: [
              "rgb(102, 204, 255)",
              "rgb(51, 153, 255)",
              "rgb(0, 102, 255)",
              "rgb(0, 51, 255)",
            ],
            positions: [0, 0.3, 0.5, 1.0],
          },
        });

      this.scene?.addLayer(layer);
    },

    addSpeedAmount({ list = [] }) {
      console.log("this.map", this.map, list);
      if (!this.map) {
        return;
      }

      const { AMap } = window;
      var markers = [];

      var markerContent = "";
      list.forEach((item) => {
        markerContent = `<div class='speed-amount'>
        <div style='background-color:${item.color};border-right:1px solid #fff;'>流量:${item.count}</div>
         <div  style='background-color:${item.secondColor}'>流速:${item.secondCount}</div>
         </div>
      `;
        const marker = new AMap.Marker({
          position: [item.lng, item.lat],
          content: markerContent,
          extData: item,
        });

        marker.on("click", (e) => {
          console.log(e);
          // 将折线添加至地图实例
          const id = e.target._opts.extData.id;
          this.visible = true;
          this.id = id;

          // await fetchDetail(id);

          // this.detail = e.feature.properties;
        });
        markers.push(marker);
      });

      this.map.add(markers);
    },

    setInflow(list) {
      const { AMap } = window;
      var layer = new AMap.LabelsLayer({
        zIndex: 100,
      });
      var markers = [];

      list.forEach((item) => {
        const marker = new AMap.LabelMarker({
          position: [item.lng, item.lat],
          text: {
            content: item.count + "",
            direction: "center",
            style: {
              fontSize: 12,
              fontWeight: "normal",
              fillColor: "#fff",
              padding: "2, 5",
              backgroundColor: item.color,
              borderColor: "#fff",
              borderWidth: 1,
            },
          },
          extData: item,
        });
        marker.on("click", (e) => {
          console.log(e);
          // 将折线添加至地图实例
          const id = e.target._opts.extData.id;
          this.dischargeVisible = true;
          this.id = id;
        });
        markers.push(marker);
        layer.add(marker);
      });

      // 图层添加到地图
      this.map.add(layer);
    },

    addLine({ list = [] }) {
      // console.log("this.map", this.map, list);
      if (!this.map) {
        return;
      }

      const { AMap } = window;

      if (list[0] && Array.isArray(list[0].nodes)) {
        list.forEach((item) => {
          if (Array.isArray(item.nodes)) {
            var path = item.nodes.map((element) => {
              return new AMap.LngLat(element.lng, element.lat);
            });

            // 创建折线实例
            var polyline = new AMap.Polyline({
              path: path,
              strokeWeight: 5, // 线条宽度，默认为 1
              strokeColor: item.color, // 线条颜色
              showDir: true,
              extData: {
                id: item.id ? item.id : "dont find id",
              },
            });

            this.map.add(polyline);
          }
        });
      }
    },

    ...mapMutations("map", [
      "setActiveRainId",
      "setRainVisible",
      "setActiveWaterId",
      "setWaterVisible",

      "setActiveRehearsalWaterId",
      "setRehearsalWaterVisible",
      "setFlowId",
      "setFlowVisible",
    ]),

    onMarkerClick(e) {
      const { id, type } = e.target._opts.extData;
      if (type === "rain") {
        this.setActiveRainId(id);
        this.setRainVisible(true);
      }

      if (type === "water") {
        this.setActiveWaterId(id);
        this.setWaterVisible(true);
      }

      if (type === "rehearsal-water") {
        this.setActiveRehearsalWaterId(id);
        this.setRehearsalWaterVisible(true);
      }

      if (type === "flow") {
        this.setFlowId(id);
        this.setFlowVisible(true);
      }
    },
    removeMarkerClickEvent(list) {
      if (Array.isArray(list)) {
        list.forEach((item) => {
          item.off("click", this.onMarkerClick);
        });
      }
    },
    // 事件处理函数
    async costPlannedAmountChange(value) {
      this.pid = value
    }
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 10;
}

.amap-marker-label {
  border: 1px solid #53c4f7;
}
</style>

<style lang="less">
.speed-amount {
  color: #fff;
  font-size: 10px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border: 1px solid #fff;
  > div {
    padding: 0.25em 0.5em;
  }
}
.count-container {
  padding: 0.25em 0.5em;
  color: #fff;
  font-size: 10px;
}
</style>
