<template>
  <div>
    <a-modal
      :visible="visible"
      title="详情"
      @cancel="$emit('cancel')"
      :footer="null"
      width="1200px"
    >
      <div class="title">流量(m/s)</div>
      <WaterQualityChart :list="amountList" name="water-amount-chart" />
    </a-modal>
  </div>
</template>

<script>
import WaterQualityChart from "./water-quality-chart.vue";
import { fetchLaterDetail } from "@/api/water-model";
export default {
  components: {
    WaterQualityChart,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    pid: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      flowList: [],
      amountList: [],
      speedList: [],
    };
  },

  watch: {
    id() {
      this.getData();
    },
  },

  methods: {
    async getData() {
      if (!this.id) return;
      let res = await fetchLaterDetail(this.id, this.pid);
      if (!res) {
        return;
      }
      this.amountList = res
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 1em;
}
</style>
