<template>
  <div>
    <div class="panel">
      <div class="header">推演参数</div>
      <div class="body">
        <div class="label" style="padding-top: 12px">
          <span>工况</span>
        </div>

        <div class="field">
          <a-select size="small" style="width: 80%" v-model="selectedCategory">
            <a-select-option
              v-for="(item, index) in categorySelectList"
              :key="index"
              :value="item"
            >
              <span :title="item">
                {{ item }}
              </span>
            </a-select-option>
          </a-select>
        </div>

        <div class="label">节点参数</div>
        <div class="field">
          <a-radio-group v-model="selectedSubType">
            <a-radio
              style="display: block"
              v-for="(item, index) in subTypeList1"
              :key="index"
              :value="item.value"
            >
              <span>
                {{ item.label }}
              </span>
            </a-radio>
          </a-radio-group>
        </div>

        <div class="label">河段参数</div>
        <div class="field">
          <a-radio-group v-model="selectedSubType">
            <a-radio
              :style="{
                display: 'block',
              }"
              v-for="(item, index) in subTypeList2"
              :key="index"
              :value="item.value"
            >
              <span>
                {{ item.label }}
              </span>
            </a-radio>
          </a-radio-group>
        </div>

        <div class="scale-box">
          <div
            v-if="
              selectedSubType && selectedSubType === 'hydro_discharge_velocity'
            "
          >
            <div class="scale-container">
              <div class="text">
                <div>12</div>
                <div>流量</div>
                <div>-84</div>
              </div>
              <div class="scale2"></div>
              <div class="text">
                <div>3</div>
                <div>流速</div>
                <div>-1000</div>
              </div>
            </div>
          </div>
          <div
            v-else-if="
              selectedSubType && selectedSubType === 'hydro_lateral_discharge'
            "
          >
            <div class="scale-container">
              <div class="scale2"></div>
              <div class="text">
                <div>5</div>
                <div>0</div>
              </div>
            </div>
          </div>
          <div class="scale" v-else-if="selectedSubType">
            <div
              v-for="(item, index) in colorList.find(
                (item) => item.type === selectedSubType
              ).list"
              :key="index"
              class="item"
            >
              <div class="color" :style="{ background: item.color }"></div>
              <div class="value">{{ item.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tool">
      <div class="icon" @click="play" style="margin-right: 1em">
        <a-icon v-if="isPlay" type="pause-circle" />
        <a-icon v-else type="play-circle" />
      </div>

      <template v-if="dateList.length">
        <DatePicker
          :dateList="dateList"
          :activeIndex="activeDateIndex"
          @setActiveIndex="(idx) => (activeDateIndex = idx)"
        />
        <template v-if="dateList[activeDateIndex].timeList.length > 1">
          <a-slider
            style="width: 100%"
            v-model="activeTimeIndex"
            :tooltip-visible="true"
            :tipFormatter="(idx) => dateList[activeDateIndex].timeList[idx]"
            :max="dateList[activeDateIndex].timeList.length - 1"
          />
        </template>
      </template>
    </div>

    <AbilityType v-if="selectedSubType === 'hydro_link_ability'" />
  </div>
</template>
<script>
import request from "@/api/request";
import AbilityType from "./components/ability-type.vue";

import DatePicker from "./components/date-picker.vue";
import { debounce } from "lodash";

import { mapActions, mapMutations, mapState } from "vuex";

function fetchCategory() {
  return request({
    url: "/model-analysis/hydro/category/list",
  });
}
function fetchTimeList({ type, pid }) {
  return request({
    url: "/model-analysis/hydro/timerange/" + type + "/" + pid,
  });
}

export default {
  components: {
    DatePicker,
    AbilityType,
  },

  data() {
    return {
      list: [], // 流速

      selectedCategory: "",
      categoryList: [],
      categorySelectList: [],

      selectedSubType: null,

      subTypeList1: [
        {
          label: "水深",
          value: "hydro_dynamic_waterdepth",
        },
        {
          label: "水位",
          value: "hydro_dynamic_waterlevel",
        },
        {
          label: "流量流速",
          value: "hydro_discharge_velocity",
        },

        {
          label: "旁侧入流量",
          value: "hydro_lateral_discharge",
        },
      ],
      subTypeList2: [
        {
          label: "化学需氧量",
          value: "hydro_wq_pipe_cod",
        },
        {
          label: "氨氮",
          value: "hydro_wq_pipe_nh4",
        },
        {
          label: "流量",
          value: "hydro_pipe_discharge",
        },
        {
          label: "流速",
          value: "hydro_pipe_velocity",
        },
      ],

      colorList: [
        {
          label: "水位",
          type: "hydro_dynamic_waterlevel",
          list: [
            {
              color: "#66CCFF",
              value: "<5m",
            },
            {
              color: "#3399FF",
              value: "5-10m",
            },
            {
              color: "#0066FF",
              value: "10-15m",
            },
            {
              color: "#0033FF",
              value: ">15m",
            },
          ],
        },

        {
          label: "水深",
          type: "hydro_dynamic_waterdepth",
          list: [
            {
              color: "rgb(102, 204, 255)",
              value: "0-3m",
            },
            {
              color: "rgb(51, 153, 255)",
              value: "3-5m",
            },
            {
              color: "rgb(0, 102, 255)",
              value: "5-10m",
            },
            {
              color: "rgb(0, 51, 255)",
              value: ">10m",
            },
          ],
        },

        {
          label: "化学需氧量",
          type: "hydro_wq_pipe_cod",
          list: [
            {
              color: "#0033FF",
              value: "I类",
            },
            {
              color: "#0066FF",
              value: "II类",
            },
            {
              color: "#3399FF",
              value: "III类",
            },
            {
              color: "#66CCFF",
              value: "IV类",
            },
            {
              color: "#F08080",
              value: "V类",
            },
            {
              color: "#f00",
              value: "劣V类",
            },
          ],
        },
        {
          label: "氨氮",
          type: "hydro_wq_pipe_nh4",
          list: [
            {
              color: "#0033FF",
              value: "I类",
            },
            {
              color: "#0066FF",
              value: "II类",
            },
            {
              color: "#3399FF",
              value: "III类",
            },
            {
              color: "#66CCFF",
              value: "IV类",
            },
            {
              color: "#F08080",
              value: "V类",
            },
            {
              color: "#f00",
              value: "劣V类",
            },
          ],
        },
        {
          label: "流量",
          type: "hydro_pipe_discharge",
          list: [
            {
              color: "rgb(102, 204, 255)",
              value: "-85 ~ -60m/s",
            },
            {
              color: "rgb(51, 153, 255)",
              value: "-60 ~ -35m/s",
            },
            {
              color: "rgb(0, 102, 255)",
              value: "-35 ~ -10m/s",
            },
            {
              color: "rgb(0, 51, 255)",
              value: ">-10m/s",
            },
          ],
        },
        {
          label: "流速",
          type: "hydro_pipe_velocity",
          list: [
            {
              color: "#f00",
              value: "<5cm/s",
            },
            {
              color: "#080",
              value: "<10cm/s",
            },
            {
              color: "#87CEFA",
              value: "<20cm/s",
            },
            {
              color: "#00f",
              value: ">=20cm/s",
            },
          ],
        },
      ],

      dateList: [],
      activeDateIndex: null,
      activeTimeIndex: null,

      isPlay: false,

      timeList: [],
    };
  },

  computed: {
    activeDate() {
      if (this.dateList.length > 0) {
        return this.dateList[this.activeDateIndex].date;
      } else {
        return null;
      }
    },
    activeTime() {
      if (this.dateList.length > 0) {
        return this.dateList[this.activeDateIndex].timeList[
          this.activeTimeIndex
        ];
      } else {
        return null;
      }
    },

    ...mapState("map", [
      "activeRehearsalWaterId", // 预警页面水位点 id
      "rehearsalWaterVisible",
      "flowId", // 预警页面流量点 id
      "flowVisible",
    ]),
  },

  watch: {
    selectedSubType() {
      this.setClearMap();
      this.getDate();
      this.activeTimeIndex = 0;
      this.$emit("change", this.getSelectPid());
    },

    // 用户切换日期，改变时间选中
    // 改变进度条
    activeDateIndex() {
      this.setClearMap();
      this.activeTimeIndex = 0;
    },
    activeTimeIndex() {
      this.setClearMap();
      this.debouncedRefreshData();
    },
  },

  mounted() {
    this.setClearMap();

    this.debouncedRefreshData = debounce(this.refreshData, 500);

    fetchCategory().then((res) => {
      console.log("category", res);
      if (Array.isArray(res)) {
        this.categoryList = res;
        this.categorySelectList = res
          .map((item) => item.condition)
          .filter((item, index, self) => self.indexOf(item) === index);
        if (res.length > 0) {
          this.selectedCategory = this.categorySelectList[0];

          this.selectedSubType = "hydro_dynamic_waterdepth";
        }
      }
    });
  },

  methods: {
    ...mapActions("map", ["getRehearsalList"]),
    ...mapMutations("map", ["setClearMap"]),

    // 切换日期和时间刷新数据
    // 进度条改变
    refreshData() {
      console.log(this.activeDate);
      console.log(this.activeTime);
      if (this.activeDate && this.activeTime) {
        if (this.selectedSubType) {
          // 默认加载当前选中的
          this.getList(this.selectedSubType);
        }
      }
    },

    play() {
      this.isPlay = !this.isPlay;

      if (this.isPlay) {
        this.timer = setInterval(() => {
          if (this.dateList.length > this.activeDateIndex) {
            const timeListLength =
              this.dateList[this.activeDateIndex].timeList.length;
            if (timeListLength > this.activeTimeIndex) {
              if (this.activeTimeIndex === timeListLength - 1) {
                // 最后一个时间节点
                this.activeTimeIndex = 0;
                if (this.activeDateIndex === this.dateList.length - 1) {
                  this.activeDateIndex = 0;
                } else {
                  this.activeDateIndex++;
                }
              } else {
                this.activeTimeIndex++;
              }
            }
          }
        }, 3000);
        this.$once("hook:beforeDestroy", () => {
          if (this.timer) {
            clearInterval(this.timer);
          }
        });
      } else {
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    },
    /**
     * 工况+水动力 = pid
     * @returns {*|string}
     */
    getSelectPid() {
      const item = this.categoryList.find(
        (item) =>
          this.selectedCategory === item.condition &&
          item.type === this.selectedSubType
      );
      return item ? item.id : "";
    },
    getDate() {
      const hide = this.$message.loading("加载中...");
      const selectPid = this.getSelectPid();
      fetchTimeList({
        type: this.selectedSubType,
        pid: selectPid,
      })
        .then((res) => {
          if (Array.isArray(res)) {
            const dateList = [];
            res.forEach((item) => {
              if (typeof item === "string") {
                const arr = item.split(" ");
                if (arr.length === 2) {
                  const index = dateList.findIndex(
                    (dateObj) => dateObj.date === arr[0]
                  );
                  if (index > -1) {
                    dateList[index].timeList.push(arr[1]);
                  } else {
                    dateList.push({
                      date: arr[0],
                      timeList: [arr[1]],
                    });
                  }
                }
              }
            });
            if (dateList.length > 0) {
              this.dateList = dateList;

              if (this.activeDateIndex !== 0) {
                this.activeDateIndex = 0; //重置activeDateIndex
              } else {
                this.debouncedRefreshData();
              }
            }
          }
        })
        .finally(() => {
          hide();
        });
    },

    setActive(item, element) {
      this.activeDate = item;
      this.activeTime = element;

      this.getList(this.selectedSubType);
    },

    getList(type) {
      const item = this.categoryList.find(
        (item) => this.selectedCategory === item.condition && item.type === type
      );

      this.getRehearsalList({
        ts: this.activeDate + " " + this.activeTime,
        type,
        pid: item ? item.id : "",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.panel {
  position: fixed;
  top: 12vh;
  left: 4vw;
  bottom: 64px;
  width: 280px;
  z-index: 99;

  display: flex;
  flex-direction: column;

  .header {
    background-color: #67927f;
    color: #fff;
    padding: 8px 12px;
    font-weight: bold;
  }

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;

    .label {
      // margin-right: 12px;
      background-color: rgba(0, 0, 0, 0.7);
      padding-left: 12px;
      padding-right: 12px;

      display: flex;
      align-items: center;

      & > span:first-child {
        font-weight: bold;
        color: #67927f;
      }
      & > span:last-child {
        font-size: 12px;
        color: #fff;
      }
    }

    .field {
      background-color: rgba(0, 0, 0, 0.7);
      padding: 8px 12px;
    }
  }
}

.tool {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 2vh 6vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
}

.icon {
  color: #67927f;
  font-size: 28px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scale-box {
  margin-top: auto;
}
.scale {
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  font-size: 12px;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 12px;

  .item {
    display: flex;
    align-items: center;
  }
  .color {
    height: 16px;
    width: 8px;
    margin-right: 8px;
  }
}
.scale-container {
  position: fixed;
  bottom: 120px;
  left: 24px;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  gap: 5px;
  .scale2 {
    height: 100px;
    width: 16px;
    background: linear-gradient(180deg, #0f2ebf, #18c62f, #bb3b2b);
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
