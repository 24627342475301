<template>
  <div>
    <div>
      <div :id="name" :style="{ width: '100%', height: '30vh' }"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer]);

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    name: {
      //chart div的id
      type: String,
      default: "",
    },
  },

  watch: {
    list() {
      this.setChart();
    },
  },

  mounted() {
    var chartDom = document.getElementById(this.name);
    var chart = echarts.init(chartDom);
    this.chart = chart;
    this.setChart();
  },

  methods: {
    setChart() {
      if (this.list.length === 0) {
        return;
      }

      const color = [
        "#258E36",
        "#6F83EF",
        // {
        //   type: "linear",
        //   x: 0,
        //   y: 0,
        //   x2: 0,
        //   y2: 1,
        //   colorStops: [
        //     {
        //       offset: 0,
        //       color: "#258E36",
        //     },
        //     {
        //       offset: 1,
        //       color: "#fff",
        //     },
        //   ],
        // },
        // {
        //   type: "linear",
        //   x: 0,
        //   y: 0,
        //   x2: 0,
        //   y2: 1,
        //   colorStops: [
        //     {
        //       offset: 0,
        //       color: "#6F83EF", // 0% 处的颜色
        //     },
        //     {
        //       offset: 1,
        //       color: "#fff", // 100% 处的颜色
        //     },
        //   ],
        // },
      ];
      const option = {
        color,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },

        grid: {
          top: "10%",
          right: "5%",
          bottom: "20%",
          left: "10%",
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.list.map((item) => item.ts),
        },
        yAxis: {
          type: "value",
          name: "",
          // position: "right",
          // nameTextStyle: {
          //   padding: [0, 40, 15, 0],
          // },
          // axisLine: {
          //   show: true,
          // },
        },
        series: [
          {
            name: "",
            type: "line",
            // xAxisIndex: 1,
            // yAxisIndex: 1,
            data: this.list.map((item) => item.count),
            symbol: "none",
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>
